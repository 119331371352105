<template>
    <div id="map" class="map"></div>
</template>

<script setup>
import { onMounted } from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { GET_EQUIPMENT_ONLINE_LIST } from "@/api/equipment";

onMounted(() => {
    initMap();
});

let initMap = () => {
    AMapLoader.load({
        key: "a1ff9a40678c90bd99ed2a29de533f75", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺"AMap.Scale"等
        AMapUI: {
            // 是否加载 AMapUI，缺省不加载
            version: "1.1", // AMapUI 缺省 1.1
            plugins: [] // 需要加载的 AMapUI ui插件
        },
        Loca: {
            // 是否加载 Loca， 缺省不加载
            version: "1.3.2" // Loca 版本，缺省 1.3.2
        }
    }).then((AMap) => {
        new AMap.Map("map", {});
        searchInMapSelect();
    });
};

// 高德地图选中地址
let searchInMapSelect = async () => {
    let listData = [];
    await GET_EQUIPMENT_ONLINE_LIST({
        page: 1,
        limit: 10000
    }).then((res) => {
        listData = res.data.data.list.map((item) => {
            if (item.location_gcj02) {
                if (item.location_gcj02.lon > 0 && item.location_gcj02.lat > 0) {
                    return {
                        icon: new AMap.Icon({
                            image: require("../../../../assets/img/tieta.png"),
                            size: new AMap.Size(52, 52), //设置icon的大小
                            imageSize: new AMap.Size(52, 52)
                        }),
                        position: [item.location_gcj02.lon, item.location_gcj02.lat]
                    };
                }
            }
        });
    });

    let map = new AMap.Map("map", {
        zoom: 5,
        center: [105.602725, 37.076636]
    });

    let markers = listData.filter((item) => {
        return !!item;
    });

    markers.forEach((marker) => {
        new AMap.Marker({
            map: map,
            icon: marker.icon,
            position: [marker.position[0], marker.position[1]]
        });
    });
};
</script>

<style lang="scss" scoped>
.map {
    height: calc(100vh - 122px);
}
</style>
